import ApiBase from 'modules/apis/apiBase';
import type { IResponseData } from 'modules/apis/Response';


export interface ApiOrganResponse {
    name: string;
    code: string;
    description?: string;
}

class OrganService extends ApiBase {

    getMatrix = () => {
        return this.get(`/organs`) as Promise<IResponseData<Array<ApiOrganResponse>>>;
    };

}

const organService = new OrganService();

export default organService;
