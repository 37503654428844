import { Spin } from 'antd';
import {
    createContext,
    FC,
    memo,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react';
import { ApiOrganResponse } from 'services/autoOrganService';
import organService from 'services/autoOrganService';

interface ISystemData {
    organs: ApiOrganResponse[];
}

const SystemDataContext = createContext<ISystemData>({
    organs: [],
});

const useSystemData = () => useContext(SystemDataContext);

interface IProps {
    children?: ReactNode;
}

const SystemDataLoader: FC<IProps> = memo(({ children }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<ISystemData>({ organs: [] });

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const [organs] = await Promise.all([organService.getMatrix()]);
            setData({ organs: organs.data || [] });
            setLoading(false);
        };

        fetchData();
    }, []);

    return (
        <SystemDataContext.Provider value={data}>
            <Spin spinning={loading}>{children}</Spin>
        </SystemDataContext.Provider>
    );
});

SystemDataLoader.displayName = 'System Data Loader';

export { SystemDataLoader, useSystemData };
