import { Navigate } from 'react-router-dom';
import Redirect from 'route/components/Redirect';
import routeConstant, { refactorRoute } from 'route/routeConstant';
import { constant } from 'services/Constant';
import LoaderBuilder from './components/LoaderBuilder';
import donorDetailRoutes from './DonorRoutes';
import matchingRoutes from './MatchRoutes';
import patientDetailRoutes from './PatientRoutes';

const Login = LoaderBuilder(() => import('view/Authenticate/Login'));

const ResetPassword = LoaderBuilder(() =>
    import('view/Authenticate/ResetPassword')
);

const ForgotPassword = LoaderBuilder(() =>
    import('view/Authenticate/ForgotPassword')
);

const DefaultLayout = LoaderBuilder(() =>
    import('view/DefaultLayout/DefaultLayout')
);

const Receiver = LoaderBuilder(() => import('view/Receiver/Receiver'));

const Account = LoaderBuilder(() => import('view/AccountFix/Account'));

const ChangeProfile = LoaderBuilder(() =>
    import('view/AccountFix/ChangeProfile')
);

const ChangePassword = LoaderBuilder(() =>
    import('view/AccountFix/ChangePassword')
);

const DonorViewPDF = LoaderBuilder(() => import('view/DonorLayout/ExportPDF'));

const RecipientViewPDF = LoaderBuilder(() =>
    import('view/RecipientLayout/ExportPDF')
);

const routes = [
    {
        name: 'Home',
        path: refactorRoute.HOME,
        component: LoaderBuilder(() => import('view/Authenticate')),
        children: [
            {
                name: 'Home - Default',
                path: refactorRoute.HOME,
                component: () => <Redirect to={refactorRoute.LOGIN} />,
            },
            {
                name: 'Login',
                path: refactorRoute.LOGIN,
                component: Login,
            },
            {
                name: 'Forgot Password',
                path: constant.ROUTE_FORGOT_PASSWORD,
                component: ForgotPassword,
            },
            {
                name: 'Reset Password',
                path: constant.ROUTE_RESET_PASSWORD,
                component: ResetPassword,
            },
        ],
    },
    {
        name: 'Donor Detail',
        path: refactorRoute.DONOR_DETAIL,
        component: LoaderBuilder(() => import('view/DonorDetail')),
        children: donorDetailRoutes,
    },
    {
        name: 'Donor PDF',
        path: refactorRoute.DONOR_DETAIL_PDF,
        component: LoaderBuilder(() => import('view/DonorDetail/PDF')),
    },
    {
        name: 'Patient Detail',
        path: refactorRoute.PATIENT_DETAIL,
        component: LoaderBuilder(() => import('view/PatientDetail')),
        children: patientDetailRoutes,
    },
    {
        name: 'Default Layout',
        path: '/',
        component: DefaultLayout,
        children: [
            {
                name: 'Default Route',
                path: '/',
                component: () => (
                    <Navigate to={refactorRoute.DONOR_REGISTER_LIST} />
                ),
            },
            {
                name: 'Receiver',
                path: constant.ROUTE_RECEIVER,
                component: Receiver,
            },
            {
                name: 'Account',
                path: constant.ROUTE_ACCOUNT,
                component: Account,
            },
            {
                name: 'Change Account',
                path: constant.ROUTE_ACCOUNT_CHANGE_PROFILE,
                component: ChangeProfile,
            },
            {
                name: 'Change Password',
                path: constant.ROUTE_ACCOUNT_CHANGE_PASSWORD,
                component: ChangePassword,
            },

            {
                name: 'Donor Register List', // Danh sach dang ky hien
                path: refactorRoute.DONOR_REGISTER_LIST,
                component: LoaderBuilder(() =>
                    import('view/Donor/Views/Register')
                ),
            },
            {
                name: 'Receiver Register List', // Danh sach dang ky nhan
                path: refactorRoute.RECEIVER_REGISTER_LIST,
                component: LoaderBuilder(() =>
                    import('view/Receiver/Views/Register')
                ),
            },
            {
                name: 'Donor Waiting List', // Danh sach dang ky hien
                path: refactorRoute.DONOR_WAITING_LIST,
                component: LoaderBuilder(() =>
                    import('view/Donor/Views/Waiting')
                ),
            },
            {
                name: 'Receiver Waiting List', // Danh sach nguoi nhan
                path: refactorRoute.RECEIVER_WAITING_LIST,
                component: LoaderBuilder(() =>
                    import('view/Receiver/Views/Waiting')
                ),
            },
            {
                name: 'Donor Donated List', // Danh sach da hien
                path: refactorRoute.DONOR_DONATED_LIST,
                component: LoaderBuilder(() =>
                    import('view/Donor/Views/Donated')
                ),
            },
            {
                name: 'Receiver Received List', // Danh sach nguoi nhan
                path: refactorRoute.RECEIVER_RECEIVED_LIST,
                component: LoaderBuilder(() =>
                    import('view/Receiver/Views/Received')
                ),
            },
            {
                name: 'Donor Rejected List', // Danh sach loai hien
                path: refactorRoute.DONOR_REJECTED_LIST,
                component: LoaderBuilder(() =>
                    import('view/Donor/Views/Rejected')
                ),
            },
            {
                name: 'Receiver Received List', // Danh sach nguoi nhan
                path: refactorRoute.RECEIVER_REJECTED_LIST,
                component: LoaderBuilder(() =>
                    import('view/Receiver/Views/Rejected')
                ),
            },
            ...matchingRoutes,
        ],
    },
    {
        name: 'Donor View PDF',
        path: routeConstant.ROUTE_DONOR__VIEW_PDF,
        component: DonorViewPDF,
    },
    {
        name: 'Recipient View PDF',
        path: refactorRoute.PATIENT_DETAIL_PDF,
        component: RecipientViewPDF,
    },
];

export default routes;
