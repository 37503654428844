import { refactorRoute } from 'route/routeConstant';
import { constant } from '../services/Constant';
import { common } from './Common';

const getAccessToken = () => {
    return localStorage.getItem(constant.BEARER_TOKEN_VARIABLE_NAME);
};

const checkExpiredToken = async data => {
    if (data.error_code === 401) {
        common.removeBearerToken();
        window.location.hash = '#' + refactorRoute.LOGIN;
    }
};

export const tokenUtil = {
    getAccessToken,
    checkExpiredToken,
};
