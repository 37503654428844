import LoaderBuilder from './components/LoaderBuilder';
import { refactorRoute } from './routeConstant';

const matchingRoutes = [
    {
        name: 'Danh sach phu hop',
        path: refactorRoute.MATCHING_DONOR,
        component: LoaderBuilder(() => import('view/Matching/pages/matrix')),
    },
    {
        name: 'So sanh',
        path: refactorRoute.COMPARE_DONOR,
        component: LoaderBuilder(() => import('view/Matching/pages/detail')),
    },
];

export default matchingRoutes;
