import dayjs from 'dayjs';
import {
    organsOption,
    reasonsOptions,
} from 'view/DonorLayout/DeceasedDonorLayout/common';
import { constant } from '../services/Constant';

const saveBearerToken = data => {
    localStorage.setItem(constant.BEARER_TOKEN_VARIABLE_NAME, data.accessToken);
};

const removeBearerToken = () => {
    localStorage.removeItem(constant.BEARER_TOKEN_VARIABLE_NAME);
};

const getParamValue = (paramName, query) => {
    let objResult = query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
              .split('&')
              .reduce((params, param) => {
                  let [key, value] = param.split('=');
                  params[key] = value
                      ? decodeURIComponent(value.replace(/\+/g, ' '))
                      : '';
                  return params;
              }, {})
        : {};
    return objResult[paramName] || '';
};

const convertToDateTime = (ISOString, dateTimeFormat) => {
    if (!ISOString || ISOString === '--') {
        return '--';
    } else {
        const stillUtc = dayjs(ISOString).toDate();
        return dayjs(stillUtc).format(
            dateTimeFormat || constant.dateTimeFormat
        );
    }
};
const convertToDate = (ISOString, dateFormat) => {
    if (!ISOString || ISOString === '--') {
        return '--';
    }
    const stillUtc = dayjs(ISOString).toDate();
    return dayjs(stillUtc).format(dateFormat || constant.dateFormat);
};

const renderGender = text => {
    if (text === 'MALE') {
        return 'Nam';
    } else if (text === 'FEMALE') {
        return 'Nữ';
    } else {
        return '--';
    }
};

const renderYesNo = text => {
    if (text === '1') {
        return 'Có';
    } else if (text === '0') {
        return 'Không';
    } else {
        return '--';
    }
};

const convertDateStringToMoment = date => {
    return dayjs(date);
};

const renderBloodGroup = data => {
    let blood = data?.healthy;
    if (blood?.blood_rh === 'RH+') {
        return blood?.blood_group + '+';
    } else if (blood?.blood_rh === 'RH-') {
        return blood?.blood_group + '-';
    } else {
        return blood?.blood_group;
    }
};

const capitalizeFirstLetterEachWord = value =>
    value.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

const renderList = (text, options) => {
    return text
        .split(',')
        .map(item => {
            return options.find(option => option.value === item)?.label;
        })
        .join(', ');
};

const renderOrgan = text => {
    return renderList(text, organsOption);
};

const renderReason = text => {
    return renderList(text, reasonsOptions);
};

const renderSuitability = text => {
    if (text === 'phu_hop') {
        return 'Phù hợp';
    } else if (text === 'khong_phu_hop') {
        return 'Không phù hợp';
    }
};
const birthDayToAge = date => {
    if (!date) {
        return '--';
    }

    const birthDay = dayjs(date);
    const now = dayjs();
    return now.diff(birthDay, 'years');
};

export const common = {
    saveBearerToken,
    removeBearerToken,
    getParamValue,
    convertToDateTime,
    convertToDate,
    renderGender,
    convertDateStringToMoment,
    renderBloodGroup,
    renderYesNo,
    capitalizeFirstLetterEachWord,
    renderOrgan,
    renderReason,
    renderSuitability,
    birthDayToAge,
};
