// Account router
const ROUTE_FORGOT_PASSWORD = '/forgot-password';
const ROUTE_RESET_PASSWORD = '/reset/password';

const ROUTE_RECEIVER = '/danh-sach-cho-nhan';
const ROUTE_ACCOUNT = '/account';
const ROUTE_ACCOUNT_CHANGE_PROFILE = '/account/profile/change';
const ROUTE_ACCOUNT_CHANGE_PASSWORD = '/account/password/change';

const BEARER_TOKEN_VARIABLE_NAME = 'token';
const USER = 'user';
const dateTimeFormat = 'HH:mm - DD/MM/YYYY';
const dateFormat = 'DD/MM/YYYY';
const timeFormat = 'HH:mm';

const halfLayout = {
    wrapperCol: 12,
    labelCol: {
        span: 12,
    },
};

const emailRegex = /\S+@\S+\.\S+/;
const phoneNumberRegex = /((09|03|07|08|05)+([0-9]{8})\b)/g;
export const DEFAULT_PAGE_SIZE = 25;

export const formRules = {
    requiredField: {
        required: true,
        message: 'Trường này bắt buộc nhập.',
    },
};

export const constant = {
    ROUTE_FORGOT_PASSWORD,
    ROUTE_RESET_PASSWORD,
    ROUTE_RECEIVER,
    ROUTE_ACCOUNT,
    BEARER_TOKEN_VARIABLE_NAME,
    dateTimeFormat,
    dateFormat,
    timeFormat,
    halfLayout,
    emailRegex,
    phoneNumberRegex,
    USER,
    ROUTE_ACCOUNT_CHANGE_PROFILE,
    ROUTE_ACCOUNT_CHANGE_PASSWORD,
};
